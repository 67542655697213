$('#loginUser').click(function () {
    var data = {
        email: $('#email').val(),
        password: $('#password').val()
    }

    if(!email || !password){
        toastr.error("Please check the username and password and try again.", 'Error');
        return
    }

    $.ajax({
        url: "/login",
        type: "POST",
        data: data,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        success: function (data) {
            window.location.href = data.url;
        },
        error: function (data) {
            toastr.error(data.responseJSON.message, 'Error');
        }
    });
});
